import React, {useEffect, useState} from 'react'
import {IGateCategory, leadingInitValues as initialValues} from './GateCategoryAddModel';
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    createNewGateType,
    editGaeTypeById,
    getChosenGateTypesDrives,
    getChosenGateTypesHeight,
    getChosenGateTypesWidth
} from "./GateCategoryFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {getAllDrivesGroups} from "../drive/DriveFunctions";
import CustomSelect from "../../../component/customSelect/CustomSelect";
import {createComponentItemImg} from "../../componentCreate/ComponentsCreateFunctions";
import {getAllSizes} from "../sizes/SizesFunctions";
import {changePosition} from "../../../component/customChangePosition/customChangePosition";

const leadingSchema = Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa bramy" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "Ktm" jest wymagane').nullable(),
})

const GateCategoryCreateForm: React.FC = (props) => {
    const {setChosenListItem, chosenListItem, modalOpen, setOpen, gateTypes} = props;
    const client = useQueryClient();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
    const [initValues, setInitValues] = useState<{}>(initialValues);
    const [showImg, setShowImg] = useState<boolean>(true);
    const [showRender, setShowRender] = useState<boolean>(true);
    const {
        data: chosenGateTypesWidth,
        isLoading
    } = useQuery(["chosenGateTypesWidth"], () => getChosenGateTypesWidth(accessToken, chosenListItem?.id), {
        enabled: !!chosenListItem?.id,
        refetchOnWindowFocus: false,
    });
    const {data: chosenGateTypesHeight} = useQuery(["chosenGateTypesHeight"], () => getChosenGateTypesHeight(accessToken, chosenListItem?.id), {
        enabled: !!chosenListItem?.id,
        refetchOnWindowFocus: false
    });
    const {data: chosenGateTypesDrives} = useQuery(["chosenGateTypesDrives"], () => getChosenGateTypesDrives(accessToken, chosenListItem?.id), {
        enabled: !!chosenListItem?.id,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (chosenListItem && !isLoading) {
            setInitValues({
                ...chosenListItem,
                widths: chosenGateTypesWidth?.widths,
                heights: chosenGateTypesHeight?.heights,
                driveGroups: chosenGateTypesDrives?.driveGroups
            })
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem, chosenGateTypesWidth, chosenGateTypesHeight, chosenGateTypesDrives])

    const componentItemImgMutation = useMutation(createComponentItemImg)

    const editGateTypeMutation = useMutation(editGaeTypeById, {
        onSuccess: () => {
            client.invalidateQueries('gate_types')
            client.invalidateQueries('gate_types_paginated')
        }
    })

    const createNewGateMutation = useMutation((data: {
        name: string,
        drives: string[]
    }) => createNewGateType(accessToken, data), {
        onSuccess: () => {
            client.invalidateQueries('gate_types')
            client.invalidateQueries('gate_types_paginated')
        }
    })

    const changePositionMutation = useMutation(changePosition)
    const formik = useFormik<IGateCategory>({
        enableReinitialize: true,
        initialValues: initValues,
        validationSchema: leadingSchema,
        onSubmit: (values) => {
            const options = {
                onSuccess: () => {
                    formik.resetForm()
                    setChosenListItem(null)
                    setInitValues(initialValues)
                    setTimeout(() => {
                        setOpen(prev => !prev)
                    }, 2000)
                }
            }
            if (chosenListItem && chosenListItem.id) {
                componentItemImgMutation.mutate({
                    accessToken,
                    data: values?.file,
                    render: null
                }, {
                    onSuccess: (data) => {
                        componentItemImgMutation.mutate({
                            accessToken,
                            data: null,
                            render: values?.render
                        }, {
                            onSuccess: (render) => {
                                editGateTypeMutation.mutate({
                                    accessToken,
                                    data: {
                                        name: values?.name,
                                        ktm: values?.ktm,
                                        image: data ? data.id : chosenListItem.image ? chosenListItem.image?.id : null,
                                        render: render ? render.id : chosenListItem.render ? chosenListItem.render?.id : null,
                                        isAutomatic: values?.isAutomatic,
                                        isIndustrial: values?.isIndustrial,
                                        ktmDescription: values?.ktmDescription,
                                        widths: values?.widths?.map(w => w.id),
                                        heights: values?.heights?.map(h => h.id),
                                        gateTypeOrder: values.gateTypeOrder,
                                        isHidden: values.isHidden,
                                        driveGroups: values?.driveGroups?.map(drive => drive.id),
                                        translations: {
                                            en: {
                                                name: values.translations?.en?.name
                                            },
                                            ru: {
                                                name: values.translations?.ru?.name
                                            }
                                        },
                                    },
                                    gateTypeId: chosenListItem?.id
                                }, options)
                            }
                        })
                    }
                })

            } else {
                componentItemImgMutation.mutate({
                    accessToken,
                    data: values.file,
                    render: null
                }, {
                    onSuccess: (data) => {
                        componentItemImgMutation.mutate({
                            accessToken,
                            data: null,
                            render: values.render
                        }, {
                            onSuccess: (render) => {
                                createNewGateMutation.mutate({
                                    name: values?.name,
                                    ktm: values?.ktm,
                                    image: data?.id,
                                    isAutomatic: values?.isAutomatic,
                                    isIndustrial: values?.isIndustrial,
                                    render: render?.id,
                                    isHidden: values.isHidden,
                                    ktmDescription: values?.ktmDescription,
                                    widths: values?.widths?.map(w => w.id),
                                    heights: values?.heights?.map(h => h.id),
                                    driveGroups: values?.driveGroups?.map(drive => drive.id),
                                    translations: {
                                        en: {
                                            name: values.translations?.en?.name
                                        },
                                        ru: {
                                            name: values.translations?.ru?.name
                                        }
                                    },
                                }, {
                                    onSuccess: (d) => {
                                        changePositionMutation.mutate({
                                            url: "gate_types",
                                            id: d.id,
                                            data: {
                                                gateTypeOrder: gateTypes?.totalItems + 1
                                            },
                                            accessToken
                                        }, {
                                            onSuccess: () => {
                                                client.invalidateQueries('gate_types_paginated')
                                                client.invalidateQueries('gate_types')
                                            }
                                        })

                                        setTimeout(() => {
                                            setOpen(prev => !prev)
                                        }, 2000)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        },
    })
    const {data: drives} = useQuery(["drives", chosenListItem?.id], () => getAllDrivesGroups(accessToken), {
        refetchOnWindowFocus: false,
        // enabled: !!chosenListItem?.id
    });

    const {data: widths} = useQuery(["allWidths", chosenListItem?.id], () => getAllSizes(accessToken, "widths"), {
        refetchOnWindowFocus: false,
        // enabled: !!chosenListItem?.id
    });
    const {data: heights} = useQuery(["allHeights", chosenListItem?.id], () => getAllSizes(accessToken, "heights"), {
        refetchOnWindowFocus: false,
        // enabled: !!chosenListItem?.id
    });
    const options = drives?.map((group: { id: string, name: string }) => {
        return {value: group?.id, label: group?.name}
    })

    const optionsWidth = widths?.map((group: { id: string, value: string }) => {
        return {value: group?.id, label: group?.value}
    })

    const optionsHeight = heights?.map((group: { id: string, value: string }) => {
        return {value: group?.id, label: group?.value}
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                {(!createNewGateMutation.isLoading && !editGateTypeMutation.isLoading) && (!createNewGateMutation.isSuccess && !editGateTypeMutation.isSuccess) &&
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwa
                                            bramy</label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Nazwa bramy...'
                                                {...formik.getFieldProps('name')}
                                            />
                                        </div>
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Change avatar'> Rodzaj napędu</label>
                                        <Field
                                            className="custom-select"
                                            name="driveGroups"
                                            options={options}
                                            component={CustomSelect}
                                            placeholder="Wybierz rodzja napędu..."
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                                <p className='col-lg-12 col-form-label fw-bold fs-4 '>Tłumaczenia</p>
                                <div className='d-flex flex-column col-12'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku angielskim'
                                            {...formik.getFieldProps('translations.en.name')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 mb-5'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku rosyjskim'
                                            {...formik.getFieldProps('translations.ru.name')}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-12 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>Czy to jest brama
                                            automatyczna?</label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='checkbox'
                                                checked={formik.values.isAutomatic}
                                                {...formik.getFieldProps('isAutomatic')}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>Czy to jest brama
                                            przemysłowa?</label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='checkbox'
                                                checked={formik.values.isIndustrial}
                                                {...formik.getFieldProps('isIndustrial')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Kod KTM</label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Kod KTM...'
                                                {...formik.getFieldProps('ktm')}
                                            />
                                        </div>
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>Opis
                                            skrócony</label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Opis skrócony...'
                                                {...formik.getFieldProps('ktmDescription')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Width'>Szerokość</label>
                                        <Field
                                            className="custom-select"
                                            name="widths"
                                            options={optionsWidth}
                                            component={CustomSelect}
                                            placeholder="Wybierz powiązane szerokości..."
                                            isMulti={true}
                                        />
                                    </div>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Change avatar'>Wysokość</label>
                                        <Field
                                            className="custom-select"
                                            name="heights"
                                            options={optionsHeight}
                                            component={CustomSelect}
                                            placeholder="Wybierz powiązane wysokości..."
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-12 d-flex flex-sm-row flex-column'>
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6 text-uppercase'
                                               style={{color: 'red'}}>Blokada</label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='checkbox'
                                                checked={formik.values.isHidden}
                                                {...formik.getFieldProps('isHidden')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6 d-flex flex-sm-row flex-column'>

                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <div className="symbol symbol-45px">
                                            {showImg && chosenListItem?.image?.contentUrl && <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setChosenListItem({
                                                        ...chosenListItem,
                                                        image: null,
                                                    })
                                                }}
                                                className='position-absolute d-flex justify-content-center align-items-center cursor-pointer'
                                                style={{
                                                    width: '20px',
                                                    color: 'white',
                                                    height: '20px',
                                                    backgroundColor: 'red',
                                                    borderRadius: '50%',
                                                    left: -5,
                                                    top: -5,
                                                    outline: 'none',
                                                    border: 'none'
                                                }}>&#10006;
                                            </button>}
                                            {showImg && chosenListItem?.image?.contentUrl ? <img
                                                style={{
                                                    height: 'auto',
                                                    width: '100%',
                                                    maxWidth: 100
                                                }}
                                                src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.image?.contentUrl}
                                                alt="item foto"
                                            /> : null}
                                        </div>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Change avatar'> Zdjęcie</label>
                                        <input id="file" name="file" type="file" onChange={(event) => {
                                            setShowImg(false)
                                            formik.setFieldValue("file", event.currentTarget.files[0]);
                                        }}/>
                                    </div>

                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <div className="symbol symbol-45px">
                                            {showRender && chosenListItem?.render?.contentUrl && <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setChosenListItem({
                                                        ...chosenListItem,
                                                        render: null,
                                                    })
                                                }}
                                                className='position-absolute d-flex justify-content-center align-items-center cursor-pointer'
                                                style={{
                                                    width: '20px',
                                                    color: 'white',
                                                    height: '20px',
                                                    backgroundColor: 'red',
                                                    borderRadius: '50%',
                                                    left: -5,
                                                    top: -5,
                                                    outline: 'none',
                                                    border: 'none'
                                                }}>&#10006;
                                            </button>}
                                            {showRender && chosenListItem?.render?.contentUrl ? <img
                                                style={{
                                                    height: 'auto',
                                                    width: '100%',
                                                    maxWidth: 100
                                                }}
                                                src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.render?.contentUrl}
                                                alt="item foto"
                                            /> : null}
                                        </div>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'
                                               title='Change avatar'> Render</label>
                                        <input id="file2" name="file2" type="file" onChange={(event) => {
                                            setShowRender(false)
                                            formik.setFieldValue("render", event.currentTarget.files[0]);
                                        }}/>
                                    </div>
                                </div>
                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                {(createNewGateMutation.isIdle || editGateTypeMutation.isIdle) &&
                                    <button type='submit' className='btn btn-primary' disabled={!formik.isValid}
                                            style={{backgroundColor: formik.isValid ? '#009EF7' : 'grey'}}>
                                        {chosenListItem ? 'Zapisz' : 'Dodaj'}
                                    </button>}
                            </div>
                        </form>
                    </FormikProvider>}
                {(createNewGateMutation.isLoading || editGateTypeMutation.isLoading) && (
                    <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                    </div>

                )}
                {(createNewGateMutation.isSuccess || editGateTypeMutation.isSuccess) &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                {(createNewGateMutation.isError || editGateTypeMutation.isError) &&
                    <p className="text-center fw-bold" style={{color: "red", fontSize: 16}}>Coś poszło nie tak! Spróbuj
                        ponownie!</p>}
            </div>
        </div>
    )
}

export {GateCategoryCreateForm}
