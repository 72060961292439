import React, {useEffect, useMemo, useState} from 'react'
import {componentItemsInitValues as initialValues, IComponentItems,} from './ComponentAddModel';
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import {useMutation, useQueryClient} from "react-query";
import {
    createComponentItem,
    createComponentItemImg,
    createComponentItemRender,
    deleteRenderById,
    updateComponentItemById
} from "../componentCreate/ComponentsCreateFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useParams} from "react-router-dom";
import CustomSelect from "../../component/customSelect/CustomSelect";
import _ from "lodash";

const ComponentCreateFormValidation = [Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "Kod KTM" jest wymagane').nullable(),
}), Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane').nullable(),
    ktm: Yup.string().required('Pole "Kod KTM" jest wymagane').nullable(),
    perMeter: Yup.string().required('Pole "Ilość elementów na mb" jest wymagane').nullable(),
})]

export const priceTypes = [
    {value: "0", label: 'Stała cena'},
    {value: "1", label: 'Cena za metr bieżący '},
    {value: "2", label: 'Cena za m2 '},
    {value: "3", label: 'Cena na zapytanie '},
]

const ComponentCreateForm: React.FC<{
    component: { title: string, component_items: {}[] },
    chosenListItem: {} | null,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => {
    const {chosenListItem, setOpen, component, setChosenListItem} = props;
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const params = useParams();
    const client = useQueryClient();
    const [showImg, setShowImg] = useState<boolean>(true);
    const [showRender, setShowRender] = useState<boolean>(true);
    const [perMeterValidation, setPerMeterValidation] = useState<boolean>(false);
    const [initValues, setInitValues] = useState<IComponentItems>(initialValues);
    const componentItemImgMutation = useMutation(createComponentItemImg)
    const componentItemImgRenderMutation = useMutation(createComponentItemRender)
    const deleteRenderMutation = useMutation(deleteRenderById)
    const [isDepthFormulaActive, setIsDepthFormulaActive] = useState(false);
    const options: [] = component.gateTypes?.map((gate: { id: string, name: string }) => {
        return {value: gate.id, label: gate.name}
    })
    const componentItemCreateMutation = useMutation(createComponentItem, {
        onSuccess: (id) => {
            client.invalidateQueries('components')
            client.invalidateQueries('componentsItems')
            setTimeout(() => {
                setOpen(prev => !prev)
            }, 2000)
        }
    })
    const componentItemUpdateMutation = useMutation(updateComponentItemById, {
        onSuccess: (id) => {
            client.invalidateQueries('components')
            client.invalidateQueries('componentsItems')
            setTimeout(() => {
                setOpen(prev => !prev)
            }, 2000)
        }
    })

    useEffect(() => {
        if (chosenListItem) {
            console.log(chosenListItem)
            const groupedItems = _.groupBy(chosenListItem.componentItemsValues, "component.id")
            console.log(groupedItems)
            setInitValues({
                    ...chosenListItem,
                    componentItemsValues: groupedItems,
                    price: chosenListItem.price / 100,
                    priceType: chosenListItem?.priceType.toString(),
                    minDepthType: chosenListItem?.definedMinDepth ? 1 : 0,
                    minLintelType: chosenListItem?.lintelFormula ? 0 : 1,
                    translations: {
                        en: {
                            name: chosenListItem.translations?.en?.name
                        },
                        ru: {
                            name: chosenListItem.translations?.ru?.name
                        }
                    },
                }
            )
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])
    const formik = useFormik<IComponentItems>({
        initialValues: initValues,
        enableReinitialize: true,
        validationSchema: ComponentCreateFormValidation[perMeterValidation ? 1 : 0],
        onSubmit: (values) => {
            let flattenValuesComponentItemsValues = [];
            if (values?.componentItemsValues) {
                const valuesComponentItemsValues = component.relatedComponents.map(e => {
                    const v = values?.componentItemsValues[e.id]
                    if (v !== undefined) {
                        return v;
                    }
                })
                flattenValuesComponentItemsValues = valuesComponentItemsValues.filter(e => e !== undefined).flat(1).map(e => e?.id)
            }


            const options = {
                onSuccess: () => {
                    formik.resetForm()
                    setChosenListItem(null)
                    setInitValues(initialValues)
                    setTimeout(() => {
                        setOpen(prev => false)
                    }, 2000)
                }
            }
            if (chosenListItem && chosenListItem.id) {
                componentItemImgRenderMutation.mutate({
                    accessToken,
                    renderFiles: values?.componentRender ? Object.entries(values?.componentRender) : undefined,
                    colorId: chosenListItem.id
                })
                componentItemImgMutation.mutate({
                    accessToken,
                    data: values.file,
                    render: null
                }, {
                    onSuccess: (data) => {
                        componentItemUpdateMutation.mutate({
                            accessToken,
                            data: {
                                name: values?.name,
                                description: values?.description,
                                ktm: values?.ktm,
                                ktmDescription: values?.ktmDescription,
                                youtubeUrl: values?.youtubeUrl,
                                component: params?.id,
                                componentItemsValues: flattenValuesComponentItemsValues,
                                gateTypes: values?.gateTypes?.map(gate => gate.id),
                                image: data?.id,
                                price: values?.price * 100,
                                priceType: +values.priceType,
                                depthFormula: values?.minDepthType === 0 ? values.depthFormula : null,
                                lintelFormula: values?.minLintelType === 0 ? values.lintelFormula : null,
                                minLintel: values?.minLintelType === 1 ? +values.minLintel : null,
                                definedMinDepth: values?.minDepthType === 1 ? +values.definedMinDepth : null,
                                perMeter: values?.perMeter?.length === 0 ? null : values?.perMeter,
                                roofAngleRequired: values.roofAngleRequired,
                                translations: {
                                    en: {
                                        name: values.translations?.en?.name
                                    },
                                    ru: {
                                        name: values.translations?.ru?.name
                                    }
                                },
                            },
                            id: chosenListItem.id
                        }, options)
                    }
                })
            } else {
                componentItemImgMutation.mutate({
                    accessToken,
                    data: values.file,
                    render: null
                }, {
                    onSuccess: (data) => {
                        componentItemCreateMutation.mutate({
                            accessToken,
                            data: {
                                name: values?.name,
                                description: values?.description,
                                ktm: values?.ktm,
                                ktmDescription: values?.ktmDescription,
                                youtubeUrl: values?.youtubeUrl,
                                component: params?.id,
                                componentItemsValues: flattenValuesComponentItemsValues,
                                gateTypes: values?.gateTypes?.map(gate => gate.id),
                                image: data?.id,
                                price: values?.price * 100,
                                priceType: +values.priceType,
                                depthFormula: values?.minDepthType === 0 ? values.depthFormula : null,
                                definedMinDepth: values?.minDepthType === 1 ? +values.definedMinDepth : null,
                                lintelFormula: values?.minLintelType === 0 ? values.lintelFormula : null,
                                minLintel: values?.minLintelType === 1 ? +values.minLintel : null,
                                perMeter: values.perMeter,
                                componentOrder: component.componentItems.length + 1,
                                roofAngleRequired: values.roofAngleRequired,
                                translations: {
                                    en: {
                                        name: values.translations?.en?.name
                                    },
                                    ru: {
                                        name: values.translations?.ru?.name
                                    }
                                },
                            }
                        }, {
                            onSuccess: (d) => {
                                componentItemImgRenderMutation.mutate({
                                    accessToken,
                                    renderFiles: values?.componentRender ? Object.entries(values?.componentRender) : undefined,
                                    colorId: d.id
                                }, options)
                            }
                        })
                    }
                })
            }
        },
    })
    useEffect(() => {
        if (formik.values.priceType === '1') {
            setPerMeterValidation(true)
        } else {
            setPerMeterValidation(false)
        }
    }, [formik.values.priceType])
    const crossedValues = useMemo(() =>
        crossValues(Object.values(formik.values.componentItemsValues || {})[0], Object.values(formik.values.componentItemsValues || {})[1]), [formik.values.componentItemsValues, chosenListItem?.id]);
    const crossedValuesForm = useMemo(() =>
            crossedValues.map(cV => {
                const render = chosenListItem?.colorItemRenders?.find(render => render.name === cV.componentTwo.id + "_" + cV.componentOne.id) || chosenListItem?.colorItemRenders?.find(render => render.name === cV.componentOne.id + "_" + cV.componentTwo.id)
                return (
                    <div className='d-flex flex-column col-3 mb-3'>
                        <div className="symbol symbol-45px">
                            {showRender && !!chosenListItem?.colorItemRenders?.find(e => e?.id === render?.id) && !!chosenListItem?.colorItemRenders?.length &&
                                <button
                                    onClick={e => {
                                        e.preventDefault()
                                        setChosenListItem({
                                            ...chosenListItem,
                                            colorItemRenders: chosenListItem.colorItemRenders.filter(e => e.name !== render?.name),
                                        })
                                        deleteRenderMutation.mutate({
                                            accessToken,
                                            id: render?.id,
                                        })
                                    }}
                                    className='position-absolute d-flex justify-content-center align-items-center cursor-pointer'
                                    style={{
                                        width: '20px',
                                        color: 'white',
                                        height: '20px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        left: -10,
                                        top: -10,
                                        outline: 'none',
                                        border: 'none'
                                    }}>&#10006;
                                </button>}
                            {render ? <img
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    maxWidth: 100
                                }}
                                src={`${process.env.REACT_APP_API_URL}` + render?.render?.contentUrl}
                                alt="item foto"
                            /> : <p>Brak zdjęcia...</p>}
                        </div>
                        <label className='col-lg-12 col-form-label fw-bold fs-6'
                               title='Change avatar'>{cV.componentTwo.name + "-" + cV.componentOne.name}</label>
                        <input id="file" name="file" type="file" onChange={(event) => {
                            setShowRender(false)
                            formik.setFieldValue(`componentRender.${cV.componentTwo.id}_${cV.componentOne.id}`, event.currentTarget.files[0]);
                        }}/>
                    </div>
                )
            })
        , [formik.values.componentItemsValues, chosenListItem?.id]);

    return (
        <>
            {<div className='card mb-5 mb-xl-10'>
                {<div className='collapse show'>
                    {((!componentItemUpdateMutation.isLoading && !componentItemCreateMutation.isLoading) && (!componentItemUpdateMutation.isSuccess && !componentItemCreateMutation.isSuccess)) &&
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6 d-flex flex-sm-row flex-column'>

                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <div className="symbol symbol-45px">
                                                {showImg && chosenListItem?.image?.contentUrl ? <img
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        maxWidth: 100
                                                    }}
                                                    src={`${process.env.REACT_APP_API_URL}` + chosenListItem?.image?.contentUrl}
                                                    alt="item foto"
                                                /> : null}
                                            </div>
                                            <label className='col-lg-6 col-form-label fw-bold fs-6'
                                                   title='Change avatar'> Zdjęcie</label>
                                            <input id="file" name="file" type="file" onChange={(event) => {
                                                setShowImg(false)
                                                formik.setFieldValue("file", event.currentTarget.files[0]);
                                            }}/>
                                        </div>
                                    </div>
                                    <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label
                                                className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwa</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Nazwa'
                                                    {...formik.getFieldProps('name')}
                                                />
                                            </div>
                                            {formik.touched.name && formik.errors.name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.name}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Rodzaj bram</label>
								        <Field
                                            className="custom-select"
                                            name="gateTypes"
                                            options={options}
                                            component={CustomSelect}
                                            placeholder="Wybierz rodzaje bram..."
                                            isMulti={true}
                                        />
                                    </span>
                                        </div>
                                    </div>

                                    <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                    <span className="d-flex flex-column">
								        <label
                                            className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">Typ ceny</label>
								        <Field
                                            className="custom-select"
                                            name="priceType"
                                            options={priceTypes}
                                            component={CustomSelect}
                                            placeholder="Wybierz typ ceny..."
                                            isMulti={false}
                                        />
                                    </span>
                                            {formik.touched.priceType && formik.errors.priceType && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.priceType}</div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label
                                                className='col-lg-6 col-form-label fw-bold fs-6'>Cena</label>
                                            <div>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Cena komponentu...'
                                                    {...formik.getFieldProps('price')}
                                                />
                                            </div>
                                            {formik.touched.price && formik.errors.price && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.price}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label
                                                className={`col-lg-6 col-form-label fw-bold fs-6 ${formik?.values.priceType === '1' ? "required" : ""}`}>Ilość
                                                elementów na mb</label>
                                            <div>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Ilość elementów na mb...'
                                                    {...formik.getFieldProps('perMeter')}
                                                />
                                            </div>
                                            {formik.touched.perMeter && formik.errors.perMeter && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.perMeter}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label
                                                className='col-lg-6 col-form-label fw-bold fs-6'>Kąt nachylenia
                                                dachu</label>
                                            <div>
                                                <input type="checkbox"
                                                       checked={formik.values.roofAngleRequired}
                                                       {...formik.getFieldProps('roofAngleRequired')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>Minimalna głębokość
                                                garażu</label>
                                            <Field
                                                className="custom-select"
                                                name="minDepthType"
                                                options={[{value: 0, label: "Wzór"}, {value: 1, label: "Wartość"}]}
                                                component={CustomSelect}
                                                placeholder="Wybierz wzór lub wartość"
                                                isMulti={false}/>
                                        </div>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>Minimalna wartość
                                                nadproża</label>
                                            <Field
                                                className="custom-select"
                                                name="minLintelType"
                                                options={[{value: 0, label: "Wzór"}, {value: 1, label: "Wartość"}]}
                                                component={CustomSelect}
                                                placeholder="Wybierz wzór lub wartość"
                                                isMulti={false}/>
                                        </div>
                                    </div>
                                    {(formik.values.minLintelType === 0 || formik.values.minDepthType === 0 || formik.values.minLintelType === 1 || formik.values.minDepthType === 1) &&
                                        <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                            {formik.values.minDepthType === 0 ?
                                                <div className='d-flex flex-column col-12 col-sm-6'>
                                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>Wzór dla
                                                        minimalnej
                                                        głębokości garażu</label>
                                                    <div className='col-lg-12 fv-row'>
                                                        <Field
                                                            name="depthFormula"
                                                            type='text'
                                                            className='form-control form-control-lg form-control-solid'
                                                            placeholder='Wprowadź wzór'
                                                            onFocus={() => setIsDepthFormulaActive(true)}
                                                            onBlur={() => setIsDepthFormulaActive(true)}
                                                        />
                                                    </div>
                                                    {formik.touched.depthFormula && formik.errors.depthFormula && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div
                                                                className='fv-help-block'>{formik.errors.depthFormula}</div>
                                                        </div>
                                                    )}
                                                </div> : formik.values.minDepthType === 1 ?
                                                    <div className='d-flex flex-column col-6 col-sm-6'>
                                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>Minimalna
                                                            głębokość
                                                            garażu(WARTOŚĆ)</label>
                                                        <div>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Wpisz minimalną głębokość garażu'
                                                                {...formik.getFieldProps('definedMinDepth')}
                                                            />
                                                        </div>
                                                    </div> : null}
                                            {formik.values.minLintelType === 0 ?
                                                <div className='d-flex flex-column col-12 col-sm-6'>
                                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>Wzór dla
                                                        minimalnej
                                                        wartości nadproża</label>
                                                    <div className='col-lg-12 fv-row'>
                                                        <Field
                                                            name="lintelFormula"
                                                            type='text'
                                                            className='form-control form-control-lg form-control-solid'
                                                            placeholder='Wprowadź wzór'
                                                            onFocus={() => setIsDepthFormulaActive(true)}
                                                            onBlur={() => setIsDepthFormulaActive(true)}
                                                        />
                                                    </div>
                                                    {formik.touched.lintelFormula && formik.errors.lintelFormula && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div
                                                                className='fv-help-block'>{formik.errors.lintelFormula}</div>
                                                        </div>
                                                    )}
                                                </div> : formik.values.minLintelType === 1 ?
                                                    <div className='d-flex flex-column col-6 col-sm-6'>
                                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>Minimalna
                                                            wartość nadproża(WARTOŚĆ)</label>
                                                        <div>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Wpisz minimalną głębokość garażu'
                                                                {...formik.getFieldProps('minLintel')}
                                                            />
                                                        </div>
                                                    </div> : null}

                                            {isDepthFormulaActive && <div
                                                className='depthFormula-warning d-flex flex-column col-12 col-sm-6 justify-content-center align-items-center mt-3'>
                                                <h5>Ważna informacja</h5>
                                                <p>Wzór może składać się wyłącznie z cyfr i zmiennych.</p>
                                                <div className="mt-3 mb-3">
                                                    <p><strong>Dostępne zmienne:</strong></p>
                                                    <p><strong>Ho</strong> - wysokość otworu</p>
                                                    <p><strong>So</strong> - szerokość otworu</p>
                                                    <p><strong>N</strong> - nadproże</p>
                                                </div>
                                                <p><strong>Przykład: Ho + So + 100</strong></p>
                                            </div>}
                                        </div>}

                                    <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-6'>
                                            <label className='col-lg-6 col-form-label required fw-bold fs-6'>Kod
                                                KTM</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Kod KTM...'
                                                    {...formik.getFieldProps('ktm')}
                                                />
                                            </div>
                                            {formik.touched.ktm && formik.errors.ktm && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.ktm}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className='d-flex flex-column col-12 col-sm-6'>
                                            <label className='col-lg-6 col-form-label fw-bold fs-6'>Opis
                                                skrócony</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Opis skrócony...'
                                                    {...formik.getFieldProps('ktmDescription')}
                                                />
                                            </div>
                                            {formik.touched.ktmDescription && formik.errors.ktmDescription && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.ktmDescription}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mb-12 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-12'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>Opis</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Opis'
                                                    {...formik.getFieldProps('description')}
                                                />
                                            </div>
                                            {formik.touched.description && formik.errors.description && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.description}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mb-12 d-flex flex-sm-row flex-column'>
                                        <div className='d-flex flex-column col-12 col-sm-12'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>Link do
                                                filmu</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Link do filmu'
                                                    {...formik.getFieldProps('youtubeUrl')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className='col-lg-12 col-form-label fw-bold fs-4 '>Tłumaczenia</p>
                                    <div className='d-flex flex-column col-12'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Wprowadź tłumaczenie w języku angielskim'
                                                {...formik.getFieldProps('translations.en.name')}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-12 mb-5'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Wprowadź tłumaczenie w języku rosyjskim'
                                                {...formik.getFieldProps('translations.ru.name')}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6 d-flex justify-content-between  flex-wrap'>
                                        {component?.relatedComponents.map((relatedComponent, index) => {
                                            const relatedComponentOptions = relatedComponent.componentItems?.map((comp: {
                                                id: string,
                                                name: string
                                            }) => {
                                                return {value: comp.id, label: comp.name}
                                            })
                                            return (
                                                <div className='d-flex flex-column col-12 col-sm-6'>
                                                <span className="d-flex flex-column">
								                    <label
                                                        className="col-lg-6 col-form-label fw-bolder text-gray-800 text-hover-primary fs-5">{relatedComponent.name}</label>
								                    <Field
                                                        className="custom-select"
                                                        name={relatedComponent.id}
                                                        options={relatedComponentOptions}
                                                        component={CustomSelect}
                                                        placeholder={`${relatedComponent.name}...`}
                                                        isMulti={true}
                                                        {...formik.getFieldProps(`componentItemsValues[${relatedComponent.id}]`)}
                                                    />
                                                </span>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {crossedValues.length > 0 &&
                                        <div className='row mb-12 d-flex flex-sm-row flex-column'>
                                            <div className='d-flex flex-column col-12 col-sm-12'>
                                                <label className='col-lg-12 col-form-label fw-bold fs-6'>Rendery</label>
                                                <div className='col-12 d-flex flex-wrap gap-5'>
                                                    {crossedValuesForm}
                                                </div>
                                            </div>
                                        </div>}
                                </div>

                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    {(componentItemCreateMutation.isIdle && componentItemUpdateMutation.isIdle) &&
                                        <button type='submit' className='btn btn-primary' disabled={!formik.isValid}
                                                style={{backgroundColor: formik.isValid ? '#009EF7' : 'grey'}}>
                                            {chosenListItem ? 'Zapisz' : 'Dodaj'}
                                        </button>}
                                </div>
                            </form>
                        </FormikProvider>}
                    {(componentItemUpdateMutation.isLoading || componentItemCreateMutation.isLoading) && (
                        <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                        </div>

                    )}
                    {(componentItemUpdateMutation.isSuccess || componentItemCreateMutation.isSuccess) &&
                        <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                </div>}
            </div>}
        </>

    )
}

export {ComponentCreateForm}

function crossValues(firstValues = [], secondValues = []) {
    const result = []
    for (const value of firstValues) {
        for (const val of secondValues) {
            result.push({componentOne: value, componentTwo: val, image: null})
        }
    }
    return result;
}
