import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {
    IACategories,
    aCategoriesInitValues as initialValues,
} from './AccessoriesCategoriesModel'
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import Select from "react-select";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchAllAdminGroups} from "../accessGroups/AccessGroupFunctions";
import CustomSelect from "../../component/customSelect/CustomSelect";
import {changePosition} from "../../component/customChangePosition/customChangePosition";

const adminSchema = Yup.object().shape({
    surname: Yup.string().required('Pole "Nazwisko" jest wymagane').nullable(),
    name: Yup.string().required('Pole "Imię" jest wymagane').nullable(),
    email: Yup.string().required('Pole "Email" jest wymagane').nullable(),
});

const AccessoriesCategoriesCreateForm: React.FC<{ chosenListItem: IACategories | null, setOpen: React.Dispatch<React.SetStateAction<boolean>> }> = (props) => {
    const {chosenListItem, setOpen, accessoriesCategories} = props;
    const client = useQueryClient();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [data, setData] = useState<IACategories | {}>({})
    const [initValues, setInitValues] = useState<IACategories>(initialValues);

    useEffect(() => {
        if (chosenListItem) {
            setInitValues(chosenListItem)
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])

    const createCatAccessory = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/accessory_categories`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return response.json()
    }

    const updateCatAccessory = async ({id, data}) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/accessory_categories/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
    }

    const aCategoryCreateMutation = useMutation(createCatAccessory, {
        onSuccess: (d) => {
            changePositionMutation.mutate({
                url: "accessory_categories",
                id: d.id,
                data: {
                    categoryOrder: accessoriesCategories?.totalItems + 1
                },
                accessToken
            }, {
                onSuccess: () => {
                    client.invalidateQueries('accessoriesCategories')
                }
            })
        }
    })

    const aCategoryEditMutation = useMutation(updateCatAccessory, {
        onSuccess: () => {
            client.invalidateQueries('accessoriesCategories')
        }
    })

    const changePositionMutation = useMutation(changePosition, {
        onSuccess: () => {
            client.invalidateQueries("accessoriesCategories")
        }
    })

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IACategories>({
        initialValues: initValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            setData(values)
            const options = {
                onSuccess: () => {
                    setTimeout(() => {
                        setOpen(prev => !prev)
                    }, 2000)
                    client.invalidateQueries("accessoriesCategories")
                }
            }
            if (chosenListItem && chosenListItem.id) {
                aCategoryEditMutation.mutate({
                    id: chosenListItem.id,
                    data: {
                        name: formik.values.name,
                        translations: {
                            en: {
                                name: values.translations?.en?.name
                            },
                            ru: {
                                name: values.translations?.ru?.name
                            }
                        },
                    }
                }, options)

            } else {
                aCategoryCreateMutation.mutate(undefined, options)
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6 '>Nazwa</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź nazwę'
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p className='col-lg-12 col-form-label fw-bold fs-4 mt-5'>Tłumaczenia</p>
                                <div className='d-flex flex-column col-12'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku angielskim'
                                            {...formik.getFieldProps('translations.en.name')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 mb-5'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź tłumaczenie w języku rosyjskim'
                                            {...formik.getFieldProps('translations.ru.name')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            {!aCategoryCreateMutation.isSuccess && !aCategoryEditMutation.isSuccess &&
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && (chosenListItem?.id ? "Zapisz" : "Dodaj")}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                    Zapisywanie...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </button>}
                        </div>
                    </form>
                </FormikProvider>
                {aCategoryEditMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                {aCategoryCreateMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Stworzono!</p>}
            </div>
        </div>
    )
}

export {AccessoriesCategoriesCreateForm}
