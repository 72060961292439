/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from './../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step1: FC<{name: string}> = ({name}) => {

  return (
      <div className="w-100">
        <div className="pb-10 pb-lg-15">
          <h2 className="fw-bolder d-flex align-items-center text-dark">Wprowadź nazwę komponentu</h2>
        </div>

        <div className="fv-row">
            <div className="row">
                <div className="me-12 min-w-400px">
                    <label className="fw-bold fs-6 mb-2">Nazwa komponentu</label>
                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='name'
                        id='kt_create_component_form_component_name'
                    />
                </div>
                <p className='col-lg-12 col-form-label fw-bold fs-4 mt-5'>Tłumaczenia</p>
                <div className='d-flex flex-column col-12'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>EN</label>

                    <div className='col-lg-12 fv-row'>
                        <Field
                            name="translations.en.name"
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Wprowadź tłumaczenie...'
                        />
                    </div>
                </div>
                <div className='d-flex flex-column col-12 mb-5'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>RU</label>

                    <div className='col-lg-12 fv-row'>
                        <Field
                            name="translations.ru.name"
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Wprowadź tłumaczenie...'
                        />
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}

export {Step1}
